import Vue from 'vue'
import Vuex from 'vuex'

import cart from './modules/cart'
import photos from './modules/photos'
import checkout from './modules/checkout'
import fairs from './modules/fairs'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    cart,
    photos,
    checkout,
    fairs
  },
  strict: debug,
})
