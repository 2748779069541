import { mapActions, mapState } from "vuex"

export default {
    name: 'Confirmation',
    data() {
        return {
            paymentStatus: ''
        }
    },
    computed: {
        ...mapState('checkout', [
            'order'
        ])
    },
    methods: {
        ...mapActions('cart', [
            'emptyOrders'
        ])
    },
    mounted() {
        this.paymentStatus = this.$route.query.redirect_status
        if (this.paymentStatus == "succeeded") this.emptyOrders()
    }
}
