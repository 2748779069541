import {mapActions, mapGetters, mapState} from 'vuex'

export default {
    name: 'Photos',
    data() {
        return {
            fair: '',
            year: '',
            block: '',
            blockCodeManual: ''
        }
    },
    computed: {
        ...mapState({
            loading: state => state.photos.loading,
            error: state => state.photos.error,
            total: state => state.photos.totalToPaginate,
            selectedSorting: state => state.photos.selectedSorting,
            fetchedDataType: state => state.photos.fetchedDataType
        }),
        ...mapGetters("photos", {
            photos: "sortedValues"
        }),
    },
    methods: {
        ...mapActions('photos', [
            'retrievePhotos'
        ]),
        showPhoto(code) {
            this.$router.push({name: 'Detail', params: {photo: code}})
        },
        showBlock() {
            this.$router.push({name: 'Photos Block', params: {fair: this.fair, year: this.year, block: this.blockCodeManual}})
            this.blockCodeManual = ''
        },
        showCodeHelp() {
            this.$swal.fire({
                title: 'Code',
                text: 'Vul hier de code in die linksonder op uw kaartje te vinden is. Deze code staat voor een blok met maximaal 100 foto’s, waardoor u niet door alle foto\'s hoeft te zoeken.'
            })
        },
        changeSorting() {
            this.$store.commit('photos/setSelectedSorting');
            this.retrievePhotos(false);
        },
        loadImage(code) {
            document.getElementById(code).style.visibility = "visible";
        },
        initializePhotos() {
            this.fair = this.$route.params.fair
            this.year = this.$route.params.year
            this.block = this.$route.params.block

            if (this.photos.length < 1) {
                this.retrievePhotos(false);
            } else {
                const currentDataType = `${this.fair}-${this.year}-${this.block}`
                
                if (this.fetchedDataType !== currentDataType) {
                    this.retrievePhotos(false);
                }
            }
        },
        retrieveFromWatch() {
            this.initializePhotos()
        }
    },
    watch: {
        // when routing between /:block and all photos, the created() or mounted() are not fired. this watch on the route fixes the bug
        '$route': 'retrieveFromWatch'
    },
    created() {
        this.initializePhotos()
    },
}
