<template>
  <div id="app">
    <notifications group="data" />
    <Header />
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/Header/Header.vue'
  export default {
    name: 'App',
    components: {
        Header
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.btn-success {
  background-color: #99ed69 !important;
  border-color: #99ed69 !important;
  color: #2c3e50 !important;
}
</style>
