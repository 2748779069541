import api from '@/services/api'
import rootStore from '../index'
import Vue from 'vue'

const state = () => ({
    // {1: [{photo: 1A1A1, product: 1, quantity: 2}, {photo: 1A1A2, product: 1, quantity: 1}], 2: [{photo: 2A1A1, product: 3, quantity: 1}]}
    orders: {},
    // {1: [{photo: {code: .., ...}, product: {name: .., ...}, quantity: 1}, {photo: {code: .., ...}, product: {name: .., ...}, quantity: 2}], 2: [{photo: {code: .., ...}, product: {name: .., ...}, quantity: 1}}
    cart: {},

    // {1: [{}, {}], 2: [{}]}
    paymentMethods: {},
    // {1: [{}, {}], 2: [{}]}
    receiveMethods: {},
    // {1: [{}, {}], 2: [{}]}
    products: {},

    error: false,
    loading: false,
    errorOrderCreation: false,
    loadingOrderCreation: false
}) 

const getters = {
    cartTotal: state => {
        return Object.keys(state.cart).reduce((total, fair) => {
            const items = state.cart[fair]
            const sum = items.reduce((sum, item) => {
                return sum + parseFloat(item.product.price) * parseInt(item.quantity)
            }, 0)
            return total + sum
        }, 0)
    },
    fairTotal: (state) => (fair) => {
        return state.cart[fair].reduce((total, item) => {
            return total + parseFloat(item.product.price) * parseInt(item.quantity)
        }, 0)
    },
    totalItems: state => {
        return Object.values(state.orders).reduce((total, items) => {
            return total + items.length
        }, 0)
    },
    getReceiveMethodsByFairId: (state) => (id) => {
        return state.receiveMethods[id]
    },
    getPaymentMethodsByFairId: (state) => (id) => {
        return state.paymentMethods[id]
    },
    getProductsByFairId: (state) => (id) => {
        return state.products[id]
    }
}

const mutations = {
    setOrders(state, orders) {
        state.orders = orders
    },
    setFairCart(state, obj){
        state.cart[obj.fair] = obj.items
    },
    emptyCart(state){
        state.cart = {}
    },
    setPaymentMethods(state, items) {
        state.paymentMethods = items
    },
    setReceiveMethods(state, items) {
        state.receiveMethods = items
    },
    setProducts(state, items) {
        state.products = items
    },
    addItem(state, item) {
        // item: {fair: photo.fair_id, photo: photo.code, product: product.id, quantity: item.quantity, isDigital: isDigital}
        const fairOrderItems = state.orders[item.fair]
        const existing = fairOrderItems.find(i => i.photo == item.photo && i.product == item.product)
        const quantity = parseInt(item.quantity)

        if (existing) {
            const maxOrderAmount = item.isDigital ? 1 : 15
            if ((existing.quantity + quantity) > maxOrderAmount) {
                Vue.swal.fire({
                    title: `Je hebt de maximale besteleenheid bereikt voor dit product`,
                    text: `Je hebt dit product al ${existing.quantity} keer in je winkelmandje en het maximum is ${maxOrderAmount}.`,
                    icon: 'warning',
                })
            } else {
                existing.quantity += quantity

                Vue.swal.fire({
                    title: `Foto ${item.photo} is nog ${item.quantity} keer toegevoegd aan je winkelmandje, dus er zitten nu ${existing.quantity} stuks in je winkelmandje.`,
                    icon: 'success'
                })
            }
        } else {
            fairOrderItems.push({photo: item.photo, product: item.product, quantity: item.quantity})

            Vue.swal.fire({
                title: `Foto ${item.photo} is ${quantity} keer toegevoegd aan je winkelmandje.`,
                icon: 'success'
            })
        }
    },
    removeItem(state, item) {
        // {fair: 1, item: {photo: 1A1A1, product: 3, quantity: 1}}
        const fairOrderItems = state.orders[item.fair]

        const index = fairOrderItems.indexOf(item.item)
        fairOrderItems[index] && fairOrderItems.splice(index, 1) 
        if (fairOrderItems.length == 0) {
            delete state.orders[item.fair]
        }
    },
    setQuantity(state, {item, quantity}) {
        item.quantity = parseInt(quantity)
    },
    setError(state, value) {
        state.error = value;
    },
    setLoading(state, value) {
        state.loading = value;
    },
    setErrorOrderCreation(state, value) {
        state.errorOrderCreation = value;
    },
    setLoadingOrderCreation(state, value) {
        state.loadingOrderCreation = value;
    },
}
    
const actions = {
    // Get full objects of photoCode and productId
    async getFullyCartItems({ state, commit, rootState, dispatch }){
        commit('setLoading', true)
        commit("setError", false);
        commit("emptyCart");

        for (const fair in state.orders) {
            var result = []

            const items = state.orders[fair]
            items.forEach(async ({photo, product, quantity }) => {
                var findProduct = () => {
                    if (state.products[fair]) {
                        return state.products[fair].find(element => element.id === product)
                    }
                }
                var findPhoto = () => rootState.photos.all[photo]
                result.push((async () => {
                    var photoObj = findPhoto();
                    var productObj = findProduct();
                    
                    if (!photoObj) {
                        await rootStore.dispatch("photos/retrievePhotoByCode", photo)
                        photoObj = findPhoto();
                    }

                    if (!productObj) {
                        await dispatch("retrieveProducts")
                        productObj = findProduct();
                    }

                    if (!photoObj.blob) await rootStore.dispatch('photos/setCachedUris')

                    return {
                        photo: photoObj,
                        product: productObj,
                        quantity
                    }
                })())
            })

            // Waiting for all the promises in the result array.
            try {
                result = await Promise.all(result)
                commit("setFairCart", {fair: fair, items: result});
            } catch(err) {
                commit("setError", true);
                console.log("Failed to fetch photos and or product data for cart.", err)
            }
        }

        commit('setLoading', false)
    },
    setCartLocalStorage({ state }) {
        localStorage.setItem('kermis-winkelwagen', JSON.stringify(state.orders))
    },
    getCartLocalStorage({commit}) {
        const localStorageItems = JSON.parse(localStorage.getItem('kermis-winkelwagen'))
        if (localStorageItems) commit('setOrders', localStorageItems)
    },
    emptyOrders({commit, dispatch}) {
        commit('setOrders', {})
        dispatch('setCartLocalStorage')
    },  
    addToCart({ state, commit, dispatch }, item) {
        // {photo: {fair_id: 1, code: 1A1A1}, product: {name: "Digitale foto"}, quantity: 1}
        const product = item.product
        const photo = item.photo
        if (!Object.prototype.hasOwnProperty.call(state.orders, photo.fair_id)) {
            Vue.set(state.orders, photo.fair_id, [])
        }

        const isDigital = product.name == "Digitale foto"

        if (isDigital) {
            const fairProduct = state.products[photo.fair_id].find(p => p.name.includes("Kermis foto"))
            const hasFairProductInCart = state.orders[photo.fair_id].some(i => i.product === fairProduct.id && i.photo === photo.code)
            if (!hasFairProductInCart) {
                Vue.swal.fire({
                    title: `Voeg eerst een kermis foto toe`,
                    text: 'Er moet minimaal 1 kermis foto worden besteld om de digitale foto te kunnen bestellen',
                    icon: 'warning'
                })
                return
            }
        }
        commit('addItem', {fair: photo.fair_id, photo: photo.code, product: product.id, quantity: item.quantity, isDigital: isDigital})
        dispatch("setCartLocalStorage")
    },
    updateCart({state, commit, dispatch}, item) {
        // {fair: 1, photo: 1A1A1, product: 1}
        const fairOrderItems = state.orders[item.fair]
        const cartItem = fairOrderItems.find(i => i.photo == item.photo && i.product == item.product)
        cartItem && commit('setQuantity', {item: cartItem, quantity: item.quantity}) 
        dispatch("setCartLocalStorage")
        dispatch("getFullyCartItems")
    },
    deleteFromCart({ state, commit, dispatch }, item) {
        // {fair: 1, photo: 1A1A1, product: 1}
        const fairOrderItems = state.orders[item.fair]
        const cartItem = fairOrderItems.find(i => i.photo == item.photo && i.product == item.product)

        const digitalProduct = state.products[item.fair].find(p => p.name == "Digitale foto")
        if (digitalProduct) {
            const digitalPhotoVersion = fairOrderItems.find(i => i.photo == item.photo && i.product == digitalProduct.id)
            if (digitalPhotoVersion && (item.product !== digitalProduct.id)) {
                Vue.swal.fire({
                    title: 'Weet je zeker dat je dit product wilt verwijderen uit je winkelmandje?',
                    text: 'De digitale foto van deze foto zal ook worden verwijderd. Het is alleen mogelijk digitale foto\'s te kopen wanneer er minimaal één ander product van de foto in je winkelmandje zit.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Verwijder beide'
                }).then((result) => {
                    if (result.isConfirmed) {
                        cartItem && commit('removeItem', {fair: item.fair, item: cartItem})
                        digitalPhotoVersion && commit('removeItem', {fair: item.fair, item: digitalPhotoVersion})
                        dispatch("setCartLocalStorage")
                        dispatch("getFullyCartItems")
                    }
                })
                return
            }
        }

        Vue.swal.fire({
            title: 'Weet je zeker dat je dit product wilt verwijderen uit je winkelmandje?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Verwijder'
        }).then((result) => {
            if (result.isConfirmed) {
                cartItem && commit('removeItem', {fair: item.fair, item: cartItem})
                dispatch("setCartLocalStorage")
                dispatch("getFullyCartItems")
            }
        })
    },
    async retrievePaymentMethods({commit}) {
        const response = await api.get('/fair_payment_methods')
        if (!response.error) commit('setPaymentMethods', response)
    },
    async retrieveReceiveMethods({commit}) {
        const response = await api.get('/fair_receive_methods')
        if (!response.error) commit('setReceiveMethods', response)
    },
    async retrieveProducts({commit}) {
        const response = await api.get('/fair_products')
        if (!response.error) commit('setProducts', response)
    },
    async placeOrder({commit}, order) {
        commit('setLoadingOrderCreation', true)
        commit('setErrorOrderCreation', false)

        const response = await api.post('/orders', {order})
        if (!response.error) {
            commit('checkout/setClientSecret', response.client_secret, { root: true })
            commit('checkout/setOrder', response.order, { root: true })
        } else {
            commit('checkout/setClientSecret', "", { root: true })
            commit('checkout/setOrder', {}, { root: true })
            commit('setErrorOrderCreation', true)
        }
        commit('setLoadingOrderCreation', false)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


