import { mapState } from "vuex"

export default {
    name: 'Home',
    computed: {
        ...mapState({
            yearly_fairs: state => state.fairs.all,
            loading: state => state.fairs.loading,
            error: state => state.fairs.error
        }),
    },
    methods: {
        goToFair(fair) {
            this.$router.push({ name: 'Photos', params: { fair: fair.name, year: fair.year } })
        }
    },
}
