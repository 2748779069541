// import api from '@/services/api'
// import rootStore from '../index'
// import Vue from 'vue'

const state = () => ({
    order: '',
    clientSecret: ''
}) 

const getters = {
}

const mutations = {
    setClientSecret(state, value) {
        state.clientSecret = value
    },
    setOrder(state, value) {
        state.order = value
    }
}
    
const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


