import {mapActions, mapGetters, mapState} from 'vuex'
import { required, email, helpers } from 'vuelidate/lib/validators'
const postcode = helpers.regex('postcode', /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)

export default {
    name: 'Cart',
    data() {
        return {
            order: {
                name: '',
                email: '',
                phone: '',
                address: '',
                postcode: '',
                place: ''
            },
            screenWidth: 0,
            selectedMethod: "Bezorgen"
        }
    },
    methods: {
        ...mapActions('cart', [
            'deleteFromCart',
            'updateCart',
            'getFullyCartItems',
            'placeOrder'
        ]),
        goToDetail(code) {
            const fair = this.getFairById(code[0])
            if (fair) {
                this.$router.push({ name: 'Detail', params: { fair: fair.name, year: fair.year, photo: code } })
            }
        },
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return

            this.order.cart = this.cart

            await this.placeOrder(this.order)

            if (this.clientSecret) {
                this.$router.replace({name: 'Checkout'})
            }
        },
        preventNav(event) {
            event.preventDefault()
            event.returnValue = ""
        },
        handleResize() {
            this.screenWidth = window.innerWidth;
        },
    },
    computed: {
        ...mapGetters('cart', [
            'cartTotal',
            'fairTotal',
            'getReceiveMethodsByFairId',
            'getPaymentMethodsByFairId',
        ]),
        ...mapGetters('fairs', [
            'getFairById'
        ]),
        ...mapState('checkout', [
            'clientSecret'
        ]),
        ...mapState('cart', [
            'cart',
            'loading',
            'error',
            'loadingOrderCreation',
            'errorOrderCreation',
        ])
    },
    mounted(){
        this.getFullyCartItems();
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("beforeunload",  this.preventNav)
        window.removeEventListener('resize', this.handleResize)
    },
    filters: {
        date(datetime) {
            if (datetime) {
                const date = new Date(datetime)
                const monthNames = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december']
                const dayNames = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag']

                const month = monthNames[date.getMonth()];
                const day = dayNames[date.getDay()];
                return `${day}, ${date.getDate()} ${month}`
            }
        },
        photos(amount) {
            return `${amount} ${amount == 1 ? 'foto' : 'foto\'s'}`
        },
        euro(price) {
            const formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2
            });
            return price ? formatter.format(parseFloat(price)) : null;
        },
    },
    validations: {
        order: {
            name: {
                required,
            },
            email: {
                required,
                email
            },
            phone: {
                required,
            },
            address: {
                required,
            },
            postcode: {
                required,
                postcode
            },
            place: {
                required,
            },
        }
    },
}
