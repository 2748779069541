import Vue from "vue"

export default {
    get(url) {
        return this.apiCall(url, 'GET')
    },
    
    post(url, data) {
        return this.apiCall(url, 'POST', data)
    },

    put(url, data) {
        return this.apiCall(url, 'PUT', data)
    },

    url() {
        return process.env.NODE_ENV == 'development' ? 'http://localhost:3000' : 'https://api.kermis-foto.nl'
    },

    async apiCall(url, method, body) {
        try {
            const response = await fetch(this.url() + url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            const data = await response.json()

            return this.errorHandling(response, data)
        } catch (error) {
            return {error: error}
        }
    },

    errorHandling(response, data) {
        if (response.status >= 400) {
            if (response.status == 422) {
                for (const key in data) {
                    if (Object.hasOwnProperty.call(data, key)) {
                        const value = data[key];
                        Vue.notify({
                            group: 'data',
                            title: `Validatie fout in ${key}`,
                            text: `${value}`,
                            type: 'error'
                        });
                    }
                }
            } else if (data.error) {
                Vue.notify({
                    group: 'data',
                    title: 'Fout opgetreden',
                    text: `${data.error}`,
                    type: 'error'
                });
            } else {
                Vue.notify({
                    group: 'data',
                    title: 'Fout',
                    text: `Er is een fout opgetreden`,
                    type: 'error'
                });
            }
            return {error: "Er is een fout opgetreden"}
        } else {
            return data
        }
    }
}