import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'Header',
    data() {
        return {

        }
    },
    methods: {
        ...mapActions('cart', [
            'getCartLocalStorage',
            'retrieveReceiveMethods',
            'retrievePaymentMethods',
            'retrieveProducts',
        ]),
        ...mapActions('fairs', [
            'retrieveFairs'
        ]),
    },
    computed: {
        ...mapGetters('cart', {
            cartItemsCount: 'totalItems'
        })
    },
    filters: {
        products(amount) {
            return `${amount} ${amount == 1 ? 'product' : 'producten'}`
        },
    },
    created() {
        this.getCartLocalStorage()         
        this.retrieveFairs()
        this.retrieveReceiveMethods()         
        this.retrievePaymentMethods()     
        this.retrieveProducts()     
    }
}
