import {mapActions, mapState, mapGetters} from 'vuex'
// import { jsPDF } from "jspdf";
import Vue from 'vue';

export default {
    name: 'Detail',
    data() {
        return {
            code: '',
            photo: {},
            products: [],
            productQuantities: {},
            imageLoading: true,
            screenWidth: 0,
            showDescriptionWidth: 1000,
            prevRoute: ''
        }
    },
    computed: {
        ...mapState({
            loading: state => state.photos.loadingOne,
            error: state => state.photos.errorOne,
        }),
        ...mapGetters('fairs', [
            'getFairById'
        ]),
        ...mapGetters('cart', [
            'getProductsByFairId'
        ]),
    },
    methods: {
        ...mapActions('cart', [
            'addToCart',
            'retrieveProducts',
        ]),
        showInfo(name, description) {
            this.$swal({
                title: name,
                text: description
            })
        },
        share() {
            var copyUrl = document.body.appendChild(document.createElement("input"));
            copyUrl.value = window.location.href;
            copyUrl.focus();
            copyUrl.select();
            document.execCommand('copy');
            copyUrl.parentNode.removeChild(copyUrl);

            this.$swal.fire({
                icon: 'success',
                text: `De link naar deze foto is gekopieerd`,
            });
        },
        setProductQuantities() {
            this.products.forEach(element => {
                this.updateQuantity(element.id, 1);
            });
        },
        updateQuantity(id, quantity) {
            Vue.set(this.productQuantities, id, quantity);
        },
        // createFairLayout() {
        //     const page = 21;

        //     const doc = new jsPDF({
        //         unit: 'cm',
        //         format: [page, page]
        //     });

        //     this.addImagesCorrectOrientation(doc, page);
        //     window.open(doc.output('bloburl'), '_blank');
        // },
        // addImage(doc, x, y, width, height, rotation) {
        //     doc.addImage(this.photo.blob, 'JPEG', x, y, width, height, this.photo.code, 'NONE', rotation);
        // },
        // addImagesCorrectOrientation(doc, pageSize) {
        //     const margin = 0.3;
        //     const between = 0.4;

        //     const largeLong = 15;
        //     const largeShort = 10;
        //     const smallLong = (pageSize - (margin * 2 + between * 2)) / 3;
        //     const smallShort = largeShort / largeLong * smallLong;

        //     switch (this.photo.orientation) {
        //         case "Horizontal (normal)":
        //             this.addImage(doc, margin + largeShort, margin + (largeShort / 2), largeLong, largeShort, 90);
        //             this.addImage(doc, margin + (largeShort * 2) + between, margin + (largeShort / 2), largeLong, largeShort, 90);

        //             this.addImage(doc, margin, margin + between + largeLong, smallLong, smallShort, 0);
        //             this.addImage(doc, margin + smallLong + between, margin + between + largeLong, smallLong, smallShort, 0);
        //             this.addImage(doc, margin + (smallLong * 2) + (between * 2), margin + between + largeLong, smallLong, smallShort, 0);
        //             break;
        //         case "Rotate 90 CW":
        //             this.addImage(doc, margin, margin - largeShort, largeLong, largeShort, 270);
        //             this.addImage(doc, margin + largeShort + between, margin - largeShort, largeLong, largeShort, 270);

        //             this.addImage(doc, margin, margin + between + largeLong, smallLong, smallShort, 0);
        //             this.addImage(doc, margin + smallLong + between, margin + between + largeLong, smallLong, smallShort, 0);
        //             this.addImage(doc, margin + (smallLong * 2) + (between * 2), margin + between + largeLong, smallLong, smallShort, 0);
        //             break;
        //         case "Rotate 180":
        //             this.addImage(doc, margin, margin - largeShort, largeLong, largeShort, 270);
        //             this.addImage(doc, margin + largeShort + between, margin - largeShort, largeLong, largeShort, 270);

        //             this.addImage(doc, margin + smallLong, margin + between + largeLong - smallShort, smallLong, smallShort, 180);
        //             this.addImage(doc, margin + (smallLong * 2) + between, margin + between + largeLong - smallShort, smallLong, smallShort, 180);
        //             this.addImage(doc, margin + (smallLong * 3) + (between * 2), margin + between + largeLong - smallShort, smallLong, smallShort, 180);
        //             break;
        //         case "Rotate 270 CW":
        //             this.addImage(doc, margin + largeShort, margin + (largeShort / 2), largeLong, largeShort, 90);
        //             this.addImage(doc, margin + (largeShort * 2) + between, margin + (largeShort / 2), largeLong, largeShort, 90);

        //             this.addImage(doc, margin + smallLong, margin + between + largeLong - smallShort, smallLong, smallShort, 180);
        //             this.addImage(doc, margin + (smallLong * 2) + between, margin + between + largeLong - smallShort, smallLong, smallShort, 180);
        //             this.addImage(doc, margin + (smallLong * 3) + (between * 2), margin + between + largeLong - smallShort, smallLong, smallShort, 180);
        //             break;
        //     }
        // },
        handleResize() {
            this.screenWidth = window.innerWidth;
        },
        goToCart() {
            this.$router.push({name: 'Cart'})
        },
        goToPhotos() {
            if (this.prevRoute.name && (this.prevRoute.name == 'Photos' || this.prevRoute.name == 'Photos Block')) {
                this.$router.go(-1)
            } else {
                const fair = this.getFairById(this.code[0])
                if (fair) {
                    this.$router.push({ name: 'Photos', params: { fair: fair.name, year: fair.year } })
                }
            }
        }
    },
    filters: {
        date(datetime) {
            if (datetime) {
                const date = new Date(datetime)
                const monthNames = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december']
                const dayNames = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag']

                const month = monthNames[date.getMonth()];
                const day = dayNames[date.getDay()];
                return `${day}, ${date.getDate()} ${month} ${date.getFullYear()}, ${date.toLocaleTimeString()}`
            }
        },
        euro(price) {
            const formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2
            });
            return formatter.format(parseFloat(price));
        },
    },
    async created() {
        this.code = this.$route.params.photo

        this.photo = this.$store.state.photos.all[this.code]
        if (!this.photo) {
            await this.$store.dispatch('photos/retrievePhotoByCode', this.code)
            this.photo = this.$store.state.photos.all[this.code]
        }

        this.products = this.getProductsByFairId(this.code[0])
        if (this.products.length == 0) {
            await this.retrieveProducts()
            this.products = this.getProductsByFairId(this.code[0])
        }

        this.setProductQuantities()
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
          vm.prevRoute = from
        })
    },
}
