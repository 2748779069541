import { mapState } from "vuex";

export default {
    name: 'Checkout',
    data() {
        return {
            idealBank: {},
            stripe: {},
            error: false,
            loading: false,
            routingToBank: false,
        }
    },
    computed: {
      ...mapState('checkout', [
        'clientSecret',
        'order'
      ])
    },
    methods: {
      showAlgemeneVoorwaarden() {
        this.$swal.fire({
          title: 'Algemene voorwaarden',
          text: 'Fotoproducten zijn speciaal voor u op maat gemaakt. Hierdoor geldt er geen herroepingsrecht zoals omschreven in de Wet Koop op Afstand. Zodra uw betaling bij ons binnen is, beginnen we direct met produceren. Vanaf dat moment is wijzigen of annuleren niet meer mogelijk.',
      })
      },
      createAndMountFormElements() {
          var options = {
            // Custom styling can be passed to options when creating an Element
            style: {
              base: {
                padding: '10px 12px',
                color: '#32325d',
                fontSize: '16px',
                '::placeholder': {
                  color: '#aab7c4'
                },
              },
            },
          };
          const elements = this.stripe.elements()
          this.idealBank = elements.create('idealBank', options);
          this.idealBank.mount('#ideal-bank-element');
      }, 
      submit() {
        this.routingToBank = true
        this.loading = true
        const returnUrl = `${window.location.origin}/confirmation`
        this.stripe.confirmIdealPayment(
          this.clientSecret,
          {
            payment_method: {
              ideal: this.idealBank,
              billing_details: {
                address: {
                  line1: this.order.address,
                  city: this.order.place,
                  country: 'NL',
                  postal_code: this.order.postcode,
                },
                email: this.order.email,
                name: this.order.name,
                phone: this.order.phone
              },
              metadata: {
                order_id: this.order.id,
                order_number: this.order.order_number
              }
            },
            return_url: returnUrl,
          }
        )
        .then((res) => {
          this.loading = false
          this.routingToBank = false
          if (res.error) {
            this.error = true
          }
        })
      },
      preventNav(event) {
        if (this.routingToBank) return
        event.preventDefault()
        event.returnValue = ""
      },
    },
    mounted() {
      const api_key = process.env.NODE_ENV === 'production' ? 'pk_live_51K5TliCYEebsUCmrlZ8sG8apINogX7x08uTJI6ZMrJMOAfphKPCLpqTzAAldOBpoMFsuwqOmxGdTtNXW04PcMwQp00WGjLoUVi' : 'pk_test_51K5TliCYEebsUCmrD5uhJrKOMJjtvzOnlN4kDVPgaxn7LV2xbvA9QN1aQschtz4MyyWhyl731h3cWH04qo7tWxZc0007J56VZi'
      this.stripe = window.Stripe(api_key)
      if (this.clientSecret) this.createAndMountFormElements()
    },
    beforeRouteLeave(to, from, next) {
      if (!window.confirm("Weet u zeker dat u deze betaalpagina wilt verlaten?")) {
        return;
      }
      next();
    },
    beforeMount() {
      window.addEventListener("beforeunload", this.preventNav)
    },
    destroyed() {
      window.removeEventListener("beforeunload", this.preventNav)
    },
    filters: {
      euro(price) {
        const formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
        });
        return price ? formatter.format(parseFloat(price)) : null;
      },
    }
}
