import api from '@/services/api'

export default {
    name: 'FAQ',
    data() {
        return {
            questions: [],
            loading: true,
            error: false
        }
    },
    methods: {
        async retrieveQuestions() {
            this.loading = true
            this.error = false

            const response = await api.get('/questions')
            if (response.error) {
                this.error = true
            } else {
                this.questions = response
            }
            this.loading = false;
        },
    },
    created() {
        this.retrieveQuestions()
    }
}
