import Vue from 'vue'
import VueRouter from 'vue-router'
import Photos from '../components/Photos/Photos.vue'
import Home from '../components/Home/Home.vue'
import Detail from '../components/Detail/Detail.vue'
import Cart from '../components/Cart/Cart.vue'
import Contact from '../components/Contact/Contact.vue'
import FAQ from '../components/FAQ/FAQ.vue'
import Confirmation from '../components/Confirmation/Confirmation.vue'
import Checkout from '../components/Checkout/Checkout.vue'
import DigitalImage from '../components/DigitalImage/DigitalImage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/images/:id',
    name: 'DigitalImage',
    component: DigitalImage
  },
  {
    path: '/:fair/:year',
    name: 'Photos',
    component: Photos
  },
  {
    path: '/:fair/:year/:block',
    name: 'Photos Block',
    component: Photos
  },
  {
    path: '/:fair/:year/foto/:photo',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/winkelwagen',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
