import api from '@/services/api'
import router from '@/router'
import Vue from 'vue'

const state = () => ({
    // All our known photos, cache
    all: {},
    // This is the order we will be displaying from our cache, received from the server.
    codesToShow: [],

    // Pagination
    page: 0,
    totalToPaginate: 0,
    
    // This value is checking if any value has changed: fair, year or block. If not the data does not have to be fetched see: Photos.js initializePhotos()
    fetchedDataType: '',

    sortings: ['Nieuw naar oud', 'Oud naar nieuw'],
    selectedSorting: 'Oud naar nieuw',
    
    loading: false,
    error: false,
    loadingOne: false,
    errorOne: false
})

const getters = {
    // ALl images in a sorted order directed by 'codesToShow'
    sortedValues(state) {
        return state.codesToShow.map(code => state.all[code])
    },
    values(state) {
        return Object.values(state.all);
    },
    codes(state){
        return Object.keys(state.all)
    }
}

const mutations = {
    addPhotos(state, photos) {
        photos.forEach( photo => {
            // If we already added it do not overwrite it. We lose our blob otherwise.
            if (!(photo.code in state.all)) {
                Vue.set(state.all, photo.code, photo);
            }
        })
    },
    computeCodesToShow(state, photos){
        const codes = photos.map(photo => photo.code)
        state.codesToShow = state.page > 0 ? state.codesToShow.concat(codes) : codes;
    },
    setUri(state, item) {
        Vue.set(item.photo, 'blob', item.url)
    },
    setPage(state, page) {
        state.page = page
    },
    setTotalToPaginate(state, total) {
        state.totalToPaginate = total
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    },
    setLoadingOne(state, loading) {
        state.loadingOne = loading
    },
    setErrorOne(state, error) {
        state.errorOne = error
    },
    setSelectedSorting(state) {
        state.selectedSorting = state.sortings[0] == state.selectedSorting ? state.sortings[1] : state.sortings[0]
    },
    setFetchedDataType(state, value) {
        state.fetchedDataType = value
    }
}

const actions = {
    async retrievePhotos({commit, state, dispatch}, addedPage) {
        // Only show loading screen on first load
        if (!addedPage) commit('setLoading', true)
        commit('setError', false)

        const page = addedPage ? state.page + 1 : 0
        commit('setPage', page)

        const response = await api.post('/photos', {
            fair_name: router.currentRoute.params.fair,
            fair_year: router.currentRoute.params.year,
            photos_block: router.currentRoute.params.block,
            size: 20,
            page: state.page,
            sorting: state.selectedSorting
        })

        if (!response.error) {
            commit('addPhotos', response.photos)
            commit('computeCodesToShow', response.photos)
            commit('setTotalToPaginate', response.total)

            commit('setFetchedDataType', `${router.currentRoute.params.fair}-${router.currentRoute.params.year}-${router.currentRoute.params.block}`)
            dispatch('setCachedUris')
        } else {
            commit('setError', true)
        }

        if (!addedPage) commit('setLoading', false)
    },
    async retrievePhotoByCode({commit, dispatch}, code){
        commit('setLoadingOne', true)
        commit('setErrorOne', false)

        const response = await api.get(`/photos/${code}`)
        if (!response.error) {
            commit('addPhotos', [response])
            dispatch('setCachedUris')
        } else {
            commit('setErrorOne', true)
        }
        commit('setLoadingOne', false)
    },
    async setCachedUris({commit, state}) {
        for (const photo of Object.values(state.all)) {
            if (!photo.blob) {
                const blob = await (await fetch(photo.thumbnail_url)).blob()
                commit('setUri', {photo: photo, url: URL.createObjectURL(blob)})
            }
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}