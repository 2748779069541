import api from '@/services/api'

export default {
    name: 'DigitalImage',
    data() {
        return {
            id: '',
            url: undefined,
            localUrl: '',
            loading: false,
            error: false,
            imageLoading: true
        }
    },
    methods: {
        async retrieveImageUrl() {
            this.loading = true
            this.error = false

            const response = await api.get(`/digital_photos_codes/${this.id}`)
            if (!response.error) {
                this.url = response.url
                await this.createLocalDownloadUrl()
            } else {
                this.error = true
            }
            this.loading = false
        },
        async createLocalDownloadUrl() {
            const blob = await (await fetch(this.url)).blob()
            this.localUrl = URL.createObjectURL(blob)
        },
        saveImage() {
            var link = document.createElement('a');
            link.download = "kermis-foto.jpg";
            link.href = this.localUrl;
            link.click();
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.retrieveImageUrl()
    }
}
