import api from '@/services/api'

export default {
    name: 'Contact',
    data() {
        return {
            info: [],
            loading: true,
            error: false
        }
    },
    methods: {
        async retrieveInfo() {
            this.loading = true
            this.error = false

            const response = await api.get('/info')
            if (response.error) {
                this.error = true
            } else {
                this.info = response
            }
            this.loading = false;
        },
        findKey(key) {
            return this.info.find(i => i.key == key)
        }
    },
    created() {
        this.retrieveInfo()
    }
}
