import api from '@/services/api'

const state = () => ({
    all: [],
    loading: true,
    error: false
})

const getters = {
    getFairById: (state) => (id) => {
        return state.all.flatMap(element => element[1]).find(fair => fair.id == id)
    }
}

const mutations = {
    setAll(state, fairs) {
        state.all = fairs
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setError(state, error) {
        state.error = error
    }
}

const actions = {
    async retrieveFairs({commit}) {
        commit('setLoading', true)
        commit('setError', false)

        const response = await api.get('/fairs')
        if (response.error) {
            commit('setError', true)
        } else {
            commit('setAll', response)
        }
        commit('setLoading', false)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}